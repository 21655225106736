.leaderboard-index-section {
  display: flex;
  flex-direction: column;
  width: 100vw !important;
  /* height: 100vh; */
  overflow: hidden !important;
}

.align-items-stretch{
  padding-left: 15px;
  /* padding-right: 15px; */
}

.leader-board-table-section-container {
  max-width: 100%;
  overflow-x: auto; /* Ensure that the table is scrollable if it overflows */
}



.leader-board-table-container{
  border-radius: 25px ;
  background: #ffffff61;
  /* height: 32% !important; */
  /* width: 100% !important; */
  overflow: hidden !important;
}

.leader-board-table-head{
  background-color: #6000d2;
  color: white !important;
  text-align: center !important;
  font-size: 2vh !important;
  padding: 10px !important;
  font-weight: bold !important;
  font-family: 'Gellix' !important;
}
.leader-board-table-body-cell{
  text-align: center !important; 
  font-size: 2vh !important;
  padding: 10px !important;
  font-family: 'Gellix' !important;
}

.leader-board-table-user-img{
  height: 40px;
  width: 40px;
  border-radius: 40%;
}




.leader-vdo {
  width: auto !important;
  height: 32% !important;
  /* max-height: 100%;  */
  object-fit: cover !important;
  border-radius: 25px;
}

/* Optional: Customize max-width for larger screens */
@media  (min-width: 1880px) {
  .leaderboard-index-section {
    max-width: 100vw !important;
    margin: 0 auto; 
  }
  
  .align-items-stretch{
    padding-left: 40px;
    padding-right: 40px;
  }
  .leader-board-table-head{
    font-size: 3vh !important;
  }
  .leader-board-table-body-cell{
    font-size: 2.5vh !important;
  }
  .leader-board-table-user-img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
  }
}
